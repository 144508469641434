exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contentful-about-us-page-slug-tsx": () => import("./../../../src/pages/{contentfulAboutUsPage.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-about-us-page-slug-tsx" */),
  "component---src-pages-contentful-car-rent-page-slug-tsx": () => import("./../../../src/pages/{contentfulCarRentPage.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-car-rent-page-slug-tsx" */),
  "component---src-pages-contentful-contact-page-slug-tsx": () => import("./../../../src/pages/{contentfulContactPage.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-contact-page-slug-tsx" */),
  "component---src-pages-contentful-faq-page-slug-tsx": () => import("./../../../src/pages/{contentfulFaqPage.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-faq-page-slug-tsx" */),
  "component---src-pages-contentful-home-page-slug-tsx": () => import("./../../../src/pages/{contentfulHomePage.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-home-page-slug-tsx" */),
  "component---src-pages-contentful-offer-page-slug-tsx": () => import("./../../../src/pages/{contentfulOfferPage.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-offer-page-slug-tsx" */),
  "component---src-pages-contentful-project-details-page-slug-tsx": () => import("./../../../src/pages/{contentfulProjectDetailsPage.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-project-details-page-slug-tsx" */),
  "component---src-pages-contentful-projects-page-slug-tsx": () => import("./../../../src/pages/{contentfulProjectsPage.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-projects-page-slug-tsx" */)
}

